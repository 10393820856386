<template>
  <div id="app">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <router-view />
  </div>
</template>
<script>
export default {
  
};
</script>


<style lang="scss">
// 设置根元素字号
html {
  font-size: 4.26666667vw;
  overflow: hidden;
  overflow-y: auto;
}
body {
  overflow: hidden;
  overflow-y: auto;
}
* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  border: none;
  outline: none;
}
#app {
  width: 100%;
  margin: auto;
  overflow: hidden;
}
@font-face {
  font-family: "PingFang SC";
  src: url("https://res-qd.qinxueedu.cn/resources/client/font/pingfang-zhun.ttf");
}
@font-face {
  font-family: "PingFang";
  src: url("https://res-qd.qinxueedu.cn/resources/client/font/pingfang-xi.ttf");
}
</style>
