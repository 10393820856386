let a = {
    ////正式环境
    baseUrl: "https://m.qinxuejiaoyu.com",
    imgUrl: "https://res-qd.qinxueedu.cn/resources/website/wap/",
    apiUrl: "https://api.qinxuejiaoyu.com",
    wapUrl: "https://m.qinxuejiaoyu.com",
    wwwUrl:'https://www.qinxuejiaoyu.com'
}
let b = {
    ////测试
    baseUrl: "https://m.qinxuejiaoyu.com",
    imgUrl: "https://res-qd.qinxueedu.cn/resources/website/wap/",
    apiUrl: "https://api.qinxuejiaoyu.com",
    wapUrl: "https://m.qinxuejiaoyu.com",
    wwwUrl:'https://www.qinxuejiaoyu.com'
}
let c = process.env.VUE_APP_TITLE === 'development' ? b : a
export default c;
