<template>
  <div id="app">
    <!-- 底部 -->
    <div class="foot_down_box">
      <div class="foot_box">
        <div class="foot_content">
          <div class="app_logo">
            <img :src="this.url.imgUrl + foot_logo" alt="" />
            <p>勤学课堂APP</p>
          </div>
          <div>试题、视频、最新考试资讯一手掌握</div>
          <img class="download" src="../assets/xiazai.png" alt="" @click="goApp"/>
          <div>
            服务热线：400-656-1156 <br />
            服务时间：周一至周五9:00-12:00 14:00-17:00<br />
            版权所有：长沙勤学科技有限公司 湘ICP备2023001774号
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api/api";
export default {
  data() {
    return {
      options: [], // 科目列表
      value: "", // 选择的专业id
      logoImg: "qinxue_2x.png", // logo
      foot_logo: "foot_icon.png",
    };
  },

  mounted() {},

  methods: {
    // 获取专业列表
    getSubject() {
      api.subject().then((res) => {
        // console.log("科目", res);
        if (parseInt(res.data.code) == 1) {
          this.options = res.data.data;
        }
      });
    },
    // 0元体验提交
    atOnce() {
      var data = {
        mobile: 18739918813,
        ts: 18739918813,
        cid: 2,
        subject_id: this.value,
        sign: "",
      };
      api.landing_site(data).then((res) => {
        // console.log("体验--", res);
      });
    },
        // 判断是安卓还是苹果
    isApp() {
      var equipmentType = "";
      var agent = navigator.userAgent.toLowerCase();
      var android = agent.indexOf("android");
      var iphone = agent.indexOf("iphone");
      var ipad = agent.indexOf("ipad");
      if (android != -1) {
        equipmentType = false;
      }
      if (iphone != -1 || ipad != -1) {
        equipmentType = true;
      }
      return equipmentType;
    },
    goApp() {
      if (this.isApp()) {
        // 苹果
        window.location.href = "https://apps.apple.com/cn/app/id1550898543";
      } else {
        // 安卓
        window.location.href =
          "https://a.app.qq.com/o/simple.jsp?pkgname=com.gamerole.wm1207";
      }
    },
  },
  created() {
    this.getSubject();
  },
};
</script>

<style lang="scss" scoped>
#app {
  overflow: hidden;
  .foot_down_box {
    width: 100%;
    height: 13.13rem;
    // position: relative;
    .foot_box {
      width: 100%;
      // height: 13.13rem;
      background: #444444;
      .foot_content {
        height: 210px;
        margin: auto;
        display: flex;
        flex-flow: column;
        align-items: center;
        .app_logo {
          display: flex;
          align-items: center;
          margin: 1.56rem 0 0.63rem;
          img {
            width: 1.5rem;
            height: 1.5rem;
            margin-right: 0.5rem;
          }
          p {
            width: 84px;
            height: 19px;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #ffffff;
          }
        }
        div:nth-child(2) {
          width: 225px;
          height: 20px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 20px;
        }
        .download {
          width: 17.5rem;
          height: 2.25rem;
          margin: 0.63rem 0 1.25rem;
        }
        div:nth-child(4) {
          height: 51px;
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #bbbbbb;
          line-height: 17px;
          text-align: center;
        }
      }
    }
  }
}
</style>
