<template>
  <div id="app">
    <img id="back_img" src="../assets/back.png" alt="" @click="goBack" />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    goBack() {
      // console.log(1111);
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
#back_img {
  width: 1.5rem;
  height: 1.5rem;
}
</style>